import React, { useState, useEffect } from "react";
import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { navArrow } from "../../icons/svg.js";

interface IImage {
  image?: Image;
  cover?: boolean;
}

interface Props {
  images?: IImage[];
}

const MembersCarousel: React.FC<Props> = ({ images = [] }) => {
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const [numItems, setNumItems] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setNumItems(4);
      } else if (window.innerWidth >= 900) {
        setNumItems(3);
      } else if (window.innerWidth >= 650) {
        setNumItems(2);
      } else {
        setNumItems(1);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (images.length <= numItems) return;

    const interval = setInterval(() => {
      setFadeIn(false);
      setTimeout(() => {
        setCurrentGroupIndex((prevIndex) => {
          const totalGroups = Math.ceil(images.length / numItems);
          return (prevIndex + 1) % totalGroups;
        });
        setFadeIn(true);
      }, 250);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length, numItems]);

  const handleNext = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentGroupIndex((prevIndex) => {
        const totalGroups = Math.ceil(images.length / numItems);
        return (prevIndex + 1) % totalGroups;
      });
      setFadeIn(true);
    }, 250);
  };

  const handlePrev = () => {
    setFadeIn(false);
    setTimeout(() => {
      setCurrentGroupIndex((prevIndex) => {
        const totalGroups = Math.ceil(images.length / numItems);
        return (prevIndex - 1 + totalGroups) % totalGroups;
      });
      setFadeIn(true);
    }, 250);
  };

  return (
    <div className="members-container mw hs vs-xl">
      <div className="content">
        <div className="arrow left" onClick={handlePrev}>
          {navArrow}
        </div>
        <div className={`grid ${fadeIn ? "fade-in" : "fade-out"}`}>
          {Array.from({ length: numItems }).map((_, i) => {
            const imageIndex = currentGroupIndex * numItems + i;
            const image = images[imageIndex]?.image;
            const cover = images[imageIndex]?.cover;

            if (!image) return null;

            return (
              <div
                key={i}
                className={`image-container ${cover ? "cover" : ""}`}
              >
                <img
                  src={transformImageUrl(image, { width: 1000 })}
                  alt={image.altText}
                />
              </div>
            );
          })}
        </div>
        <div className="arrow right" onClick={handleNext}>
          {navArrow}
        </div>
      </div>
    </div>
  );
};

export default MembersCarousel;
